import React from 'react';
import SIngleContactInfo from '../../../components/SIngleContactInfo/SIngleContactInfo';

const ContactInfoArea = () => {
   return (
      <>
         <section className="contact__help p-relative pt-90">
            <div className="contact__shape">
               <img className="shape" src="assets/img/icon/contact/shape.png" alt="" />
            </div>
            <div className="container">
               <div className="row p-relative">

                  <SIngleContactInfo image="warning" title="Page Not Found" classContainer="col-xl-4 col-lg-12 col-md-12 offset-xl-4" >
                     <a className="z-btn z-btn-border contact-button" href="/">Go Home</a>
                  </SIngleContactInfo>

               </div>
            </div>
         </section>
      </>
   );
};

export default ContactInfoArea;