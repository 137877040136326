import React from 'react';
import {  FaRegComments } from 'react-icons/fa';
import { CgArrowLongRight } from 'react-icons/cg';
import { Link } from 'react-router-dom';

const BlogSingleItem = ({image, user_image, name, title, video_icon, entryURL, date, text}) => {
   const completeEntryUrl = entryURL

   return (
      <>
         <div className="blog__item-2 mb-50 fix" >
            <div className={`blog__thumb-2 w-img fix ${video_icon && 'p-relative'}`}>
               <Link to={completeEntryUrl}>
                  <img src={image} alt="" />
               </Link>
               {video_icon && video_icon}
            </div>
            <div className="blog__content-2">
               <div className="blog__meta-2 mb-15 d-sm-flex align-items-center">
                  <div className="blog__user pr-20 mr-20">
                     <Link to={completeEntryUrl}>
                        <img src={`assets/img/blog/user/user-${user_image}.jpg`} alt="" />
                        <h6>{name}</h6>
                     </Link>
                  </div>
                  <div className="blog__date">
                     <span>{`${date}`}</span>
                  </div>
               </div>
               <h3>
                  <Link to={completeEntryUrl}>{title}</Link>
               </h3>
               <p>
                  {`${text}`}
               </p>
               <div className="blog__btn d-sm-flex justify-content-between">
                  <div className="blog__btn">
                     <Link to={completeEntryUrl} className="link-btn-2">
                        Read More
                        <i > <CgArrowLongRight/> </i>
                        <i > <CgArrowLongRight /> </i>
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default BlogSingleItem;