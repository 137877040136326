import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedin } from 'react-icons/fa';
import BlogLeftSideBarNew from '../../Blogs/BlogsArea/BlogLeftSideBarNew';
import { BiReply } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const BlogDetailsArea = () => {
   return (
      <>
         <section className="blog__area pt-60 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        <div className="blog__text mb-40">
                           <h3>Optumus, LLC Joins athenahealth’s Marketplace Program to Provide Dedicated Staff Augmentation Services to Medical Practices in the United States</h3>
                           
                           <p>
                              <b>Boston, MA – December 01, 2023</b> – Optumus, LLC a provider of dedicated staff augmentation services today announced an agreement with <a href='https://www.athenahealth.com/' target='_blank' rel='noreferrer'>athenahealth, Inc.</a> through the company’s Marketplace program. “Optumus, LLC’s staff augmentation services help medical practices and hospitals solve ongoing staffing issues left behind by COVID-19 through providing dedicated, college educated, bilingual (English/Spanish) employees to assist practices in the areas of virtual reception, RCM support, prior authorization, and data analysis” Executive Director, Christopher McDonald, Optumus, LLC. “We provide consistency and help balance the workforce for athenaOne users in the most critical areas impacting provider burnout, and most importantly patient satisfaction.”  As part of the <a href="http://www.athenahealth.com/marketplace/" target='_blank' rel='noreferrer'>athenahealth® Marketplace,</a> these newly integrated services are now available to athenahealth’s growing network of healthcare providers.
                           </p>
                           <p>
                              Medical practices and hospitals across the nations have been grappling with the critical issue of support staff shortages.  These shortages have led to increased workloads for existing staff and has placed an unprecedented strain on the healthcare industry.  These strains have led to increased workloads for existing staff, delayed patient care and administrative challenges, ultimately impacting the overall quality and efficiency of healthcare delivery.  As the healthcare sector continues to confront this staffing crisis, the need for strategic solutions has become more critical than ever.  Through this agreement athenahealth and Optumus, LLC are addressing the crisis head-on.
                           </p>
                           <p>
                              athenahealth is a leading provider of network-enabled software and services for medical groups and health systems nationwide. Its electronic health records, revenue cycle management, and patient engagement tools allow anytime, anywhere access to drive better financial outcomes for practices and enable providers to deliver better quality care. athenahealth’s vision is to build a thriving ecosystem that delivers accessible, high-quality, and sustainable healthcare for all. As a Marketplace partner, Optumus, LLC joins a community of innovative, like-minded healthcare professionals who are looking to bring best-in-class solutions to the athenahealth provider base. 
                           </p>
                           <p>
                              To learn more about Optumus, LLC’s new integrated application, please visit Optumus LLC’s <a href='https://marketplace.athenahealth.com/product/dedicated-remote-staff-augmentation-services'>product listing page on the Marketplace.</a>
                           </p>
                           
                           <h4>About Optumus, LLC</h4>
                           <p>Optumus, LLC is a Boston-based technology company established in 2016, with offices in Boston and Mexico City.  The company specializes in providing staff augmentation services to medical practices and hospitals across the United States.  Through innovative solutions, Optumus, LLC helps healthcare institutions optimize their staffing needs, improve patient care, and streamline operations.  For more information, visit <a href="https://www.optumus.com">www.optumus.com</a></p>

                           <h4>About athenahealth Marketplace</h4>
                           <p>The athenahealth Marketplace, the largest EHR app store, is where athenahealth customers find innovative healthcare IT solutions that extend athenahealth services and allow customers to create highly tailored user experiences. Customers use Marketplace partner solutions to boost practice efficiency, increase patient satisfaction, and engage patients in their own care. The Marketplace has over 400 solutions across more than 60 medical specialties and capabilities that are seamlessly integrated with athenaOne, athenahealth’s network-enabled. Learn more at <a href="http://www.athenahealth.com/solutions/marketplace-program">www.athenahealth.com/solutions/marketplace-program.</a></p>
                           
                        </div>
                        <div className="blog__details-thumb w-img mb-45">
                           <img src="assets/img/blog/blog1/athenahealth-marketplace.jpg" alt="" />
                        </div>
                        <div className="blog__share d-flex align-items-center mb-30">
                           <span>Share : </span>
                           <div className="blog__social theme-social d-inline-block">
                              <ul>
                                 <li>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=https://optumus.com/optumus-joins-athenahealth-marketplace" target="blank">
                                       <i ><FaFacebookF /></i>
                                       <i ><FaFacebookF /></i>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="https://twitter.com/intent/tweet?text=Optumus&url=https://optumus.com/optumus-joins-athenahealth-marketplace" target="blank">
                                       <i ><FaTwitter /> </i>
                                       <i ><FaTwitter /> </i>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="https://www.linkedin.com/shareArticle?mini=true&url=https://optumus.com/optumus-joins-athenahealth-marketplace&title=Optumus" target="blank">
                                       <i ><FaLinkedin /> </i>
                                       <i ><FaLinkedin /> </i>
                                    </a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                        <div className="blog__author mb-95 d-sm-flex">
                           <div className="blog__author-img mr-30">
                              <img src="assets/img/blog/user/user-1.jpg" alt="" />
                           </div>
                           <div className="blog__author-content">
                              <h5>Optumus Insights</h5>
                              <span>Author</span>
                              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                           </div>
                        </div>
                     </div>
                  </div>

                  <BlogLeftSideBarNew/>
               </div>
            </div>
         </section>
      </>
   );
};

export default BlogDetailsArea;