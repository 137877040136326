import React from 'react';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import StyleHeaderSeven from '../HeaderStyleSeven/StyleHeaderSeven/StyleHeaderSeven';
import DevelopmentExpertArea from '../../pages/DevelopmentDetails/DevelopmentExpertArea/DevelopmentExpertArea';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';

const DevelopmentDetails = () => {
   return (
      <>
         <PageHelmet 
            pageTitle="Development Services | Optumus" 
            description="One of our founding services is our clinical content customization, reducing administration and pulling our clients away from their screens."
         />

         <StyleHeaderSeven />
         <CommonPageHeader title="Development Services" subtitle="Optumus Development" />
         <DevelopmentExpertArea />
         <CommonCtaArea />
         <Footer />
      </>
   );
};

export default DevelopmentDetails;
