import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import PageHelmet from '../../components/shared/PageHelmet';
import BlogFooter from '../Blogs/BlogFooter/BlogFooter';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import BlogDetailsArea from './BlogDetailsArea/BlogDetailsArea';
import StyleHeaderSeven from '../HeaderStyleSeven/StyleHeaderSeven/StyleHeaderSeven';
import Footer from '../../components/shared/Footer';

const BlogDetails = () => {
   return (
      <>
         <PageHelmet 
            pageTitle="Optumus, LLC Joins athenahealth’s Marketplace Program to Provide Dedicated Staff Augmentation Services | Optumus"
            description="Insights - Optumus, LLC Joins athenahealth’s Marketplace Program to Provide Dedicated Staff Augmentation Services to Medical Practices in the United States."
         />

         <StyleHeaderSeven/>

         <CommonPageHeader title="Insights" subtitle="" />
         <BlogDetailsArea/>
         <Footer/>
      </>
   );
};

export default BlogDetails;