import React from 'react';
import SIngleContactInfo from '../../../components/SIngleContactInfo/SIngleContactInfo';

const ContactInfoArea = () => {
   const onOpenChat = () => {
      const iframe = document.getElementById('tidio-chat-code')
      const iWindow = iframe.contentWindow;
      const iDocument = iWindow.document;

      // accessing the element
      const element = iDocument.getElementById("button-body");
      debugger

   }

   return (
      <>
         <section className="contact__help p-relative pt-90">
            <div className="contact__shape">
               {/* <img className="dot" src="assets/img/icon/contact/dot.png" alt="" /> */}
               <img className="shape" src="assets/img/icon/contact/shape.png" alt="" />
            </div>
            <div className="container">
               {/* <div className="row">
                  <div className="col-xl-5 offset-xl-1">
                     <div className="section__title section__title-3 mb-10">
                        <span >Contact</span>
                        <h2 >Contact Us</h2>
                     </div>
                  </div>
               </div> */}
               <div className="row p-relative">

                  <SIngleContactInfo image="headset" title="Call Us" classContainer="col-xl-4 col-lg-6 col-md-6 offset-xl-1" ><a className="z-btn z-btn-border contact-button" href="tel:(617)-848-4488">(617) 848-4488</a></SIngleContactInfo>
                  <SIngleContactInfo image="msg" title="Email Us" classContainer="col-xl-4 col-lg-6 col-md-6 offset-xl-2"><a className="z-btn z-btn-border contact-button" href="mailto:info@optumus.com">info@optumus.com</a></SIngleContactInfo>

                  {/* <SIngleContactInfo image="headset" title="Call Us" classContainer="col-xl-3 col-lg-6 col-md-6 offset-xl-1" ><a className="z-btn z-btn-border contact-button" href="tel:(617)-848-4488">(617) 848-4488</a></SIngleContactInfo>
                  <SIngleContactInfo image="email" title="Email Us" classContainer="col-xl-3 col-lg-6 col-md-6 offset-xl-1"><a className="z-btn z-btn-border contact-button" href="mailto:info@optumus.com">info@optumus.com</a></SIngleContactInfo>
                  <SIngleContactInfo image="msg" title="Chat With Us" classContainer="col-xl-3 col-lg-6 col-md-6 offset-xl-1"><button className="z-btn z-btn-border contact-button" onClick={onOpenChat} >Chat Now</button></SIngleContactInfo> */}

               </div>
            </div>
         </section>
      </>
   );
};

export default ContactInfoArea;