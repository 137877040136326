import React, { useState, useEffect } from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
// import { Link } from 'react-router-dom';
import { Link, animateScroll as scroll } from "react-scroll";

const ConsultingDetailsArea = () => {

  const [scroll, setScroll] = useState(false)
  
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 325);
    });
  }, [])


  return (
    <>
      <section className="services__details pt-115 pb-100">
        <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                  <div className={`services__sidebar mr-50 ${scroll ? `consulting-menu-fixed` : ``}`}>
                    <div className="services__widget grey-bg-18 mb-40">
                        <div className="services__widget-title">
                          <h4>Categories</h4>
                        </div>
                        <div className="services__widget-content">
                          <div className="services__link">
                              <ul>
                                <li>
                                  <Link
                                    to="project-management"
                                    spy={true}
                                    smooth={true}
                                    offset={-90}
                                    duration={500}
                                  >
                                    Project Management
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="process-improvements"
                                    spy={true}
                                    smooth={true}
                                    offset={-90}
                                    duration={500}
                                  >
                                    Process Improvements
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="training"
                                    spy={true}
                                    smooth={true}
                                    offset={-90}
                                    duration={500}
                                  >
                                    Training
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="vendor-selection"
                                    spy={true}
                                    smooth={true}
                                    offset={-90}
                                    duration={500}
                                  >
                                    Vendor Selection
                                  </Link>
                                </li>
                              </ul>
                          </div>
                        </div>
                    </div>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8 consulting">
                    <div className="services__text" id="project-management">
                        <h3>Project Management</h3>
                        <p>
                          Effective planning and execution are critical to the successful completion of any project. With decades of experience working across projects of all sizes, from full implementation to nice specialty campaigns, our team ensures a seamless process from start to finish. Our mission is to take the stress and burden off of your plate, allowing you to focus on caring for your patients. 
                        </p>
                    </div>
                    <div className="services__img mb-45 w-img">
                        <img src="assets/img/services/details/project-management.png" alt=""/>
                    </div>
                    <div className="services__text" id="process-improvements">
                        <h3>Process Improvements</h3>
                        <p>
                          While everyone wants to complete every task in the most simple and efficient way possible, this is often not the easiest thing to do. Over the years, we have seen a steady rise in inefficient workflows and processes in organizations across the industry, which cost time and money and result in employee frustration.
                          <br />
                          <br />
                          Our team has vast experience across the sector, allowing them to undertake an extensive review of your operations and identify areas of inefficiency. This will enable our consultants to create a bespoke plan that covers everything from staff training and system modifications to resource availability and physical layouts.
                        </p>
                    </div>
                    <div className="services__img mb-45 w-img">
                        <img src="assets/img/services/details/process-improvements.png" alt=""/>
                    </div>
                    <div className="services__text" id="training">
                        <h3>Training</h3>
                        <p>
                          Regular and comprehensive training is crucial to the smooth running of your organization. However, many businesses struggle to find the resources required to train staff properly, often simply pairing new team members with existing employees that perform similar roles. 
                          <br />
                          <br />
                          Although on-the-job training can be effective, our Optumus consultants will create tailored plans designed around your operations. We can undertake them as one-on-one sessions or in small groups, ensuring your team has the tools and knowledge they need to operate at the highest potential.
                        </p>
                    </div>
                    <div className="services__img mb-45 w-img">
                        <img src="assets/img/services/details/training.png" alt=""/>
                    </div>
                    <div className="services__text" id="vendor-selection">
                        <h3>Vendor Selection</h3>
                        <p>
                          Software has become a vital part of every organization in this technology-led world. However, with so many providers on the market, evaluating and deciding which option is best for your operations can be a complex and time-consuming process. Buyer’s remorse is very common amongst healthcare organizations, which is why we leverage our experience and knowledge to ensure our clients can easily find the right solutions for their needs.
                        </p>
                    </div>
                  </div>
              </div>
            </div>
      </section>
    </>
  );
};

export default ConsultingDetailsArea;
