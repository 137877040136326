import React, { useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Reaptcha from 'reaptcha'

const ContactArea = () => {
   const refName = useRef();
   const refEmail = useRef();
   const refMessage = useRef();

   const [buttonLabel, setButtonLabel] = useState("Send Message");
   const [buttonAble, setButtonAble] = useState(true);
   const [captchaVerified, setCaptchaVerified] = useState(false);
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const [modalText, setModalText] = useState("")

   const captchaSiteKey = '6Ld6YLUlAAAAAEAX5ldIOvuH8zewVnjaMW16aguB'
   const captchaSecretKey = '6Ld6YLUlAAAAAEntjzTrS24LNQrp5i7d3dOu-CpV'

   const onVerify = () =>{
      setCaptchaVerified(true)
   }

   const handleSubmit = async (event) => {
      
      event.preventDefault()

      if(!captchaVerified) {
         setModalText("Please check off the 'I am not a robot' checkbox.")
         setShow(true)
         return
      }

      setButtonLabel("Loading...")

      const form = new FormData()

      form.append('fname', refName.current.value)
      form.append('femail', refEmail.current.value)
      form.append('fmessage', refMessage.current.value)

      const url = 'https://optumus.com/php-optumus/mailing.php'
      const response = await fetch(url, {
         method: 'POST',
         body: form
      })

      // If HTTP-status is 200-299
      if (response.ok) {
         // Get the response body (the method explained below)
         const data = await response.json()

         if(data.sent == true) {
            setModalText(data.message)
            setShow(true)
         }
         
      } else {
         alert("HTTP-Error: " + response.status);
      }

      setButtonLabel("Send Message")
   }

   return (
      <>
         <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
               <Modal.Title>Contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>{ modalText }</Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={handleClose}>
                  Close
               </Button>
            </Modal.Footer>
         </Modal>
         <section className="contact__area">
            <div className="container-fluid p-0">
               <div className="row g-0">
                  <div className="col-xl-12">
                     <div className="contact__map">
                        {/* <iframe title='contact' src="https://maps.google.com/maps?hl=en&amp;q=Dhaka+()&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed"></iframe> */}
                        <iframe title='contact' src="//www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2948.2497122331506!2d-71.06060644869518!3d42.358517243089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e37a02ab6843c1%3A0x1d0e9a23a572189f!2sOptumus%2C%20LLC!5e0!3m2!1sen!2sus!4v1667487917321!5m2!1sen!2sus"></iframe>
                        <div 
                           className="contact__wrapper d-md-flex justify-content-between wow fadeInUp"
                           data-wow-delay=".3s"
                           style={{opacity: '90%'}}
                        >
                           <div className="contact__info mr-100">
                              <h3>Get in touch</h3>
                              <ul>
                                 <li>
                                    <h4>USA</h4>
                                    <p>One Boston Place, Suite #2600<br />Boston, MA 02108</p>
                                 </li>
                                 <li>
                                    <h4>Mexico</h4>
                                    <p>Insurgentes Sur 945, 1st Floor<br />Mexico City, Mexico 03710</p>
                                 </li>
                                 <li>
                                    <h4>call us</h4>
                                    <p>(617) 848-4488</p>
                                 </li>
                                 <li>
                                    <h4>Email Address</h4>
                                    <p>info@optumus.com</p>
                                 </li>
                              </ul>
                           </div>
                           <div className="contact__form">
                              <form onSubmit={handleSubmit}>

                                 <input type="text" ref={refName} name="fname" id="fname" required placeholder="Your Name" />
                                 <input type="email" ref={refEmail} name="femail" id="femail" required placeholder="Your Email" />
                                 <textarea required ref={refMessage} name="fmessage" id="fmessage" placeholder="Your Message"></textarea>
                                 <Reaptcha sitekey={captchaSiteKey} onVerify={onVerify} />
                                 
                                 <button 
                                    disabled={!buttonAble}
                                    type="submit" className="z-btn mt-20"
                                 >{ buttonLabel }</button>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ContactArea;