import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import PageHelmet from '../../components/shared/PageHelmet';
import BlogFooter from '../Blogs/BlogFooter/BlogFooter';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import BlogDetailsArea2 from './BlogDetailsArea/BlogDetailsArea2';
import StyleHeaderSeven from '../HeaderStyleSeven/StyleHeaderSeven/StyleHeaderSeven';
import Footer from '../../components/shared/Footer';

const BlogDetails = () => {
   return (
      <>
         <PageHelmet 
            pageTitle="Latinx Physicians of California Partners with Optumus Mexico to Tackle Medical Staffing Issues | Optumus"
            description="Insights - Latinx Physicians of California Partners with Optumus Mexico to Tackle Medical Staffing Issues"
         />

         <StyleHeaderSeven/>

         <CommonPageHeader title="Insights" subtitle="" />
         <BlogDetailsArea2/>
         <Footer/>
      </>
   );
};

export default BlogDetails;