import React from 'react';

const AboutCapabilities = () => {
   return (
      <>
         <section className="capabilities__area p-relative black-bg-2 pt-180 pb-155 fix">
            <div className="capabilities__thumb p-absolute" style={{ background:`url(assets/img/capabilities/capabilities-img-2.jpg)`
               , backgroundPosition: 'center', backgroundSize:'cover'}}></div>
            <div className="capabilities__shape p-absolute wow fadeInLeft" >
               <img src="assets/img/capabilities/capabilities-shape.png" alt="shape"/>
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-6" >
                     <div className="capabilities__content wow fadeInUp" data-wow-delay=".4s">
                        <div className="section__title section__title-2 mb-60">
                           {/* <span className="white-color">Capabilities</span> */}
                           <h2 className="white-color">Who We Are</h2>
                        </div>
                        <div className="capabilities__list capabilities-paragraph">
                           <p>Optumus is made up of a team of highly specialized healthcare IT executives, consultants, and administrators, with vast experience in executive, technical, financial, and administrative sectors. We leverage this knowledge to help our clients perform at their best and provide the very best care possible.</p>
                           <p>Since our inception, we have strived to create innovative solutions designed to improve efficiencies and operational quality in every aspect of medical practice management and point-of-care medicine.</p>
                           {/* <ol>
                              <li >Analysis & Idea</li>
                              <li >Design & Development</li>
                              <li >Testing & Launch</li>
                           </ol> */}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default AboutCapabilities;