import React from 'react';
import { BiMap } from 'react-icons/bi';
import { FaEnvelope, FaFacebookF, FaPhoneAlt, FaTwitter, FaVimeoV } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const StaffingInformation = () => {
   return (
      <>
         <section className="team__details pb-160">
            <div className="container">
              <div className="row">
                  <div className="col-xl-10 offset-xl-1">
                     <div className="team__details-info pt-100 pb-100">
                        <h4 >Staff Augmentation</h4>
                        <p>Employees are the backbone of every successful business. Even if an organization has the latest tools, ideal physical layouts, and modern technology, they will need the right staff in terms of skillset and number to avoid significant issues.</p>
                        <p>Finding the best new talent has never been more challenging, and many businesses are facing high turnover rates. Recruitment can be a very costly and time-consuming process, and it is incredibly frustrating to have new employees resign only a few months later.</p>
                        <p>Here at Optumus, we know the importance of effective recruiting, which is why our mission is to relieve the administrative burden so you can focus on running your business.</p>
                     </div>
                  </div>
               </div>
               <div className="team__details-inner p-relative white-bg">
                  <div className="team__details-shape p-absolute">
                     <img src="assets/img/icon/team/shape-1.png" alt=""/>
                  </div>
                  <div className="row">
                     {/* <div className="col-xl-6 col-lg-6">
                        <div className="staffing__details-img w-img mr-70">
                           <img src="assets/img/team/details/team-01.jpg" alt=""/>
                        </div>
                     </div> */}
                     {/* <div className="expart__thumb l-radius-30" style={{ background: `url(assets/img/team/details/team-01.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }} ></div> */}
                     <div className="expart__thumb l-radius-30" style={{ background: `url(assets/img/about/our-vision.jpg)`, backgroundPosition: 'right', backgroundSize: 'cover' }} ></div>
                     <div className="col-xl-6 col-lg-6"></div>
                     <div className="col-xl-6 col-lg-6">
                        <div className="team__details-content p-50">
                          <h3>Our Vision</h3>
                          <p>When we began expanding our array of services from a healthcare IT consulting and development partner, into supporting businesses with recruitment, we had one clear vision: To create outstanding careers for our employees, by providing exceptional services to our customers.</p>
                          <p>We know that no two companies are the same. That is why we always sit down with our clients to understand their own unique challenges, creating a bespoke solution designed around them. Every candidate is carefully vetted based on the skillsets required for the role, with the successful individuals then undergoing extensive training on your workflows and systems.</p>
                          <p>These individuals will learn your required practices and your providers as well as your patients and team. They are your employees in everything but name, and are dedicated to working for your success. This approach has been highly successful, and we have enjoyed a lot of very positive feedback from our clients.</p>
                          <p>We are also incredibly proud that this approach has resulted in very high retention rates and staff satisfaction scores. As part of our vision, we also regularly look for high achievers and promote within, ensuring our clients are always getting the best talent possible.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default StaffingInformation;