import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import StyleHeaderSeven from '../HeaderStyleSeven/StyleHeaderSeven/StyleHeaderSeven';
import PrivacyPolicyAchievement from '../../pages/PrivacyPolicy/PrivacyPolicyAchievement/PrivacyPolicyAchievement';
import PrivacyPolicyCapabilities from '../../pages/PrivacyPolicy/PrivacyPolicyCapabilities/PrivacyPolicyCapabilities';
import AboutInformation from '../../pages/AboutInformation/AboutInformation';

const PrivacyPolicy = () => {
   return (
      <>
         <PageHelmet 
            pageTitle="Privacy Policy | Optumus"
            description="This Privacy Policy explains how we collect, use, disclose, and safeguard personal information."
         />

         <StyleHeaderSeven/>
         <CommonPageHeader title="Privacy Policy" subtitle="Privacy Policy - Optumus" />
         <PrivacyPolicyAchievement/>
         <PrivacyPolicyCapabilities />
         {/* <AboutInformation /> */}
         {/* <CommonCtaArea/> */}
         <Footer/>
      </>
   );
};

export default PrivacyPolicy;
