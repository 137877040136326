import React from 'react';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import StyleHeaderSeven from '../HeaderStyleSeven/StyleHeaderSeven/StyleHeaderSeven';
import ConsultingDetailsArea from './ConsultingDetailsArea/ConsultingDetailsArea';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';

const ConsultingDetails = () => {
   return (
      <>
         <PageHelmet 
            pageTitle="Consulting Services | Optumus" 
            description="With decades of experience working across projects of all sizes, from full implementation to nice specialty campaigns, our team ensures a seamless process from start to finish."
         />

         <StyleHeaderSeven />
         <CommonPageHeader title="Consulting Services" subtitle="Optumus Consulting" />
         <ConsultingDetailsArea />
         <Footer />
      </>
   );
};

export default ConsultingDetails;
