import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedin } from 'react-icons/fa';
import BlogLeftSideBarNew from '../../Blogs/BlogsArea/BlogLeftSideBarNew';
import { BiReply } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const BlogDetailsArea = () => {
   return (
      <>
         <section className="blog__area pt-60 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        <div className="blog__text mb-40">
                           <h3>Latinx Physicians of California Partners with Optumus Mexico to Tackle Medical Staffing Issues</h3>

                           <p>
                              <b>April 5, 2024</b>
                           </p>
                           
                           <p>FOR IMMEDIATE RELEASE</p>
                           
                           <p>
                              Contact:
                              <br />
                              Daniel Gallagher
                              <br />
                              Optumus, LLC
                              <br />
                              Phone: (805) 305-7011
                              <br />
                              Email: <a href="mailto:dgallagher@optumus.com">dgallagher@optumus.com</a>
                           </p>
                           
                           <p>
                              Ryan Brown
                              <br />
                              Latinx Physicians of California
                              <br />
                              Phone: (408) 600-5951
                              <br />
                              Email: <a href="mailto:info@latinxphysiciansofca.org">info@latinxphysiciansofca.org</a>
                           </p>

                           <p>
                              Optumus, LLC and Latinx Physicians of California Join Forces to Tackle Staffing Shortages in Medical Practices Amid Minimum Wage Increases
                           </p>
                           
                           <p>
                              Sacramento, California March 25, 2024 – Optumus, LLC, a leading healthcare staff augmentation solutions provider, and the Latinx Physicians of California, an influential organization representing Latinx physicians and providers who care for significant numbers of Latinx patients across the state, are thrilled to announce their partnership aimed at addressing critical staffing shortages in medical practices and hospitals across California.
                           </p>
                           

                           <p>With the impending minimum wage increase to $21 per hour set to take effect in medical practices this June, many healthcare providers are grappling with challenges in maintaining adequate staffing levels. Recognizing this urgent need, Optumus, LLC and the Latinx Physicians of California have forged a strategic alliance to offer a viable solution to medical practices statewide.</p>

                           <p>Through this partnership, Optumus, LLC will leverage its extensive network and expertise in healthcare staff augmentation to connect medical practices and hospitals with qualified healthcare support professionals, medical receptionists, prior authorizations professionals, RCM support staff and data analysts for work on quality projects. The Latinx Physicians of California will play a pivotal role in facilitating this collaboration by providing valuable insights and support to ensure the seamless integration of diverse talent into California's healthcare landscape.</p>

                           <p>Dr. José Alberto Arévalo, President of the Latinx Physicians of California, expressed his enthusiasm for the partnership, stating, "Our collaboration with Optumus, LLC represents a significant step forward in our collective efforts to address the pressing staffing challenges facing medical practices across California. By joining forces, we can harness our respective strengths to not only meet the workforce needs of healthcare providers but also empower physicians to navigate the evolving healthcare landscape effectively."</p>

                           <p>Christopher McDonald, Executive Director of Optumus, LLC, echoed Dr. Arévalo’s sentiments, emphasizing the importance of innovation and collaboration in overcoming industry challenges. "At Optumus, LLC, we are committed to delivering tailored solutions that meet the unique needs of our clients. Through our partnership with the Latinx Physicians of California, we are poised to provide healthcare providers with access to top-tier talent while supporting the advancement of diversity and inclusion in the healthcare workforce."</p>

                           <p>The partnership between Optumus, LLC and the Latinx Physicians of California comes at a pivotal time as healthcare organizations grapple with the implications of California State Senate Bill 525, which mandates a minimum wage increase to $21 this June with additional increases to $25 per hour in medical practices by June of 2025.  By offering comprehensive staff augmentation solutions, the alliance aims to alleviate the strain on medical practices and enable them to thrive in a rapidly changing regulatory environment.</p>

                           <p>For more information about Optumus, LLC and the Latinx Physicians of California, please visit <a href="https://www.optumus.com/">www.optumus.com</a> and <a href="https://www.latinxphysiciansofca.org/">www.latinxphysiciansofca.org</a>, respectively.</p>

                           <hr />
                           <br />
                           <p>
                              About Optumus, LLC:
                              <br />
                              Optumus, LLC is a leading healthcare staffing solutions provider dedicated to connecting healthcare organizations with qualified professionals to meet their workforce needs. With a focus on innovation and excellence, Optumus, LLC delivers comprehensive staffing solutions tailored to the unique requirements of each client.
                           </p>

                           <p>
                              About the Latinx Physicians of California:
                              <br />
                              The Latinx Physicians of California is a prominent community based organization representing Latinx physicians and providers who care for significant numbers of Latinx patients across the state. Committed to promoting diversity, equity, and inclusion in healthcare, Latinx Physicians of California advocates for the professional advancement and well-being of Latinx healthcare professionals while serving as a catalyst for positive change in the healthcare industry.
                           </p>

                           <hr />
                           <br />
                           <p>
                              For media inquiries or interview requests, please contact:<br />
                              Daniel Gallagher<br />
                              Optumus, LLC<br />
                              Phone: (805) 305-7011<br />
                              Email: <a href="mailto:dgallagher@optumus.com">dgallagher@optumus.com</a>
                           </p>

                           <p>
                              Ryan Brown<br />
                              Latinx Physicians of California<br />
                              Phone: (408) 600-5951<br />
                              Email: <a href="mailto:info@latinxphysiciansofca.org">info@latinxphysiciansofca.org</a>
                           </p>








                        </div>
                        <div className="blog__details-thumb w-img mb-45">
                           <img src="assets/img/blog/blog2/latinx-physicians-of-california.jpg" alt="" />
                        </div>
                        <div className="blog__share d-flex align-items-center mb-30">
                           <span>Share : </span>
                           <div className="blog__social theme-social d-inline-block">
                              <ul>
                                 <li>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=https://optumus.com/optumus-joins-athenahealth-marketplace" target="blank">
                                       <i ><FaFacebookF /></i>
                                       <i ><FaFacebookF /></i>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="https://twitter.com/intent/tweet?text=Optumus&url=https://optumus.com/optumus-joins-athenahealth-marketplace" target="blank">
                                       <i ><FaTwitter /> </i>
                                       <i ><FaTwitter /> </i>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="https://www.linkedin.com/shareArticle?mini=true&url=https://optumus.com/optumus-joins-athenahealth-marketplace&title=Optumus" target="blank">
                                       <i ><FaLinkedin /> </i>
                                       <i ><FaLinkedin /> </i>
                                    </a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                        <div className="blog__author mb-95 d-sm-flex">
                           <div className="blog__author-img mr-30">
                              <img src="assets/img/blog/user/user-1.jpg" alt="" />
                           </div>
                           <div className="blog__author-content">
                              <h5>Optumus Insights</h5>
                              <span>Author</span>
                              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                           </div>
                        </div>
                     </div>
                  </div>

                  <BlogLeftSideBarNew/>
               </div>
            </div>
         </section>
      </>
   );
};

export default BlogDetailsArea;