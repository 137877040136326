import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import DetailPageHeader from '../DetailPageHeader/DetailPageHeader';
import HomeTwoHeader from '../HomeTwo/HomeTwoHeader/HomeTwoHeader';
import AboutUsDetailsArea from './AboutUsDetailsArea/AboutUsDetailsArea';
import StyleHeaderSeven from '../HeaderStyleSeven/StyleHeaderSeven/StyleHeaderSeven';
import AboutAchievement from '../../pages/AboutAchievement/AboutAchievement';
import AboutCapabilities from '../../pages/AboutCapabilities/AboutCapabilities';
import AboutInformation from '../../pages/AboutInformation/AboutInformation';

const TeamDetails = () => {
   return (
      <>
         <PageHelmet 
            pageTitle="About Us | Optumus"
            description="Optumus is made up of a team of highly specialized healthcare IT executives, consultants, and administrators, with vast experience in executive, technical, financial, and administrative sectors."
         />

         <StyleHeaderSeven/>
         <CommonPageHeader title="About Us" subtitle="About Optumus" />
         <AboutAchievement/>
         <AboutCapabilities />
         <AboutInformation />
         <CommonCtaArea/>
         <Footer/>
      </>
   );
};

export default TeamDetails;
