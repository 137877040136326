import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import AboutUs from "./pages/AboutUs/AboutUs";
import Contact from "./pages/Contact/Contact";
import HomeTwo from "./pages/HomeTwo/HomeTwo";
import Staffing from "./pages/Staffing/StaffingDetails";
import Development from "./pages/DevelopmentDetails/DevelopmentDetails";
import Consulting from "./pages/ConsultingDetails/ConsultingDetails";
import NotFound from "./pages/NotFound/NotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Insights from "./pages/Blogs/Insights";
import BlogDetails1 from "./pages/BlogDetails/BlogDetails1";
import BlogDetails2 from "./pages/BlogDetails/BlogDetails2";
// import HeaderStyleFive from "./pages/HeaderStyleFive/HeaderStyleFive";
// import HeaderStyleFour from "./pages/HeaderStyleFour/HeaderStyleFour";
// import HeaderStyleSeven from "./pages/HeaderStyleSeven/HeaderStyleSeven";
// import HeaderStyleSix from "./pages/HeaderStyleSix/HeaderStyleSix";
// import Home from './pages/Home/Home';
// import HomeThree from "./pages/HomeThree/HomeThree";
// import Portfolio from "./pages/Portfolio/Portfolio";
// import PortfolioDetails from "./pages/PortfolioDetails/PortfolioDetails";
// import Services from "./pages/Services/Services";
// import ServicesDetails from "./pages/ServicesDetails/ServicesDetails";
// import Team from "./pages/Team/Team";
// import TeamDetails from "./pages/TeamDetails/TeamDetails";


function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollTop/>
        <Routes>
            <Route path="/" element={<HomeTwo/>} />
            <Route path="/about" element={<AboutUs/>} />
            <Route path="/staff-augmentation" element={<Staffing/>} />
            <Route path="/consulting" element={<Consulting/>} />
            <Route path="/development" element={<Development/>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
            <Route path="/contact" element={<Contact/>}  />
            <Route path="/insights" element={<Insights/>}  />
            <Route path="/optumus-joins-athenahealth-marketplace" element={<BlogDetails1/>} />
            <Route path="/insights-lpoc-partners-with-optumus" element={<BlogDetails2/>} />
            <Route path="/404" element={<NotFound/>} />
            <Route path="*" element={<NotFound/>} />
            
            {/* <Route path="/services" element={<Services/>} />
            <Route path="/home" element={<Home/>} />
            <Route path="/homeTwo" element={<HomeTwo/>} />
            <Route path="/homeThree" element={<HomeThree/>} />
            <Route path="/headerStyleFour" element={<HeaderStyleFour/>} />
            <Route path="/headerStyleFive" element={<HeaderStyleFive/>} />
            <Route path="/headerStyleSix" element={<HeaderStyleSix/>} />
            <Route path="/headerStyleSeven" element={<HeaderStyleSeven/>} />
            <Route path="/servicesDetails" element={<ServicesDetails/>} />
            <Route path="/portfolio" element={<Portfolio/>} />
            <Route path="/portfolioDetails" element={<PortfolioDetails/>} />
            <Route path="/team" element={<Team/>} />
            <Route path="/teamDetails" element={<TeamDetails/>} />
            <Route path="/blogs" element={<Blogs/>} />
            <Route path="/blogDetails" element={<BlogDetails/>} />
            */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
