import React from 'react';
import { Link } from 'react-router-dom';

const HomeTwoAchievement = () => {
   return (
      <>
         <section className="achievement__area pt-135 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-6">
                     <div className="achievement__content">
                        <div className="section__title section__title-3 mb-20">
                           {/* <span>What We Do</span> */}
                           <h2>What We Do</h2>
                        </div>
                        <p>We provide industry-leading staffing and technology solutions to hospitals, health systems, and physician groups across the country. Our mission is to help organizations to gain access to practical and economical solutions that help to save time, reduce costs, and improve the quality of care they provide.</p>
                        <Link to="/contact" className="z-btn">Contact Us</Link>
                     </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-6">
                     <div className="row">
                        <div className="col-xl-7 col-sm-6">
                           <div className="achievement__thumb m-img pl-30 text-right about-img">
                              <img src="assets/img/achievement/achievement-1.jpg" alt="achievement-1"/>
                           </div>
                        </div>
                        <div className="col-xl-5 col-sm-6 d-md-none d-lg-none d-xl-block">
                           <div className="achievement__thumb w-img about-img">
                              <img src="assets/img/achievement/achievement-2.jpg" alt=""/>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoAchievement;