import React from 'react';
import { CgArrowLongLeft, CgArrowLongRight } from 'react-icons/cg';
import { FiPlay } from 'react-icons/fi';
import { IoEllipsisHorizontal } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import BlogSingleItem from '../../../components/BlogSingleItem/BlogSingleItem';
import VideoPopup from '../../../components/VideoPopup/VideoPopup';
import useGlobalContext from '../../../hooks/useGlobalContext';
import BlogLeftSideBarNew from './BlogLeftSideBarNew';

const BlogsArea = () => {
   const { setIsVideoOpen} = useGlobalContext();
   return (
      <>
         {/* <VideoPopup videoId="yJg-Y5byMMw"/> */}

         <section className="blog__area pt-40 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__wrapper mr-50">

                        <BlogSingleItem 
                           image="assets/img/blog/blog2/latinx-physicians-of-california.jpg" 
                           user_image="1" 
                           name="Optumus Insights" 
                           entryURL="/insights-lpoc-partners-with-optumus" 
                           date="Apr 5, 2024"
                           title="Latinx Physicians of California Partners with Optumus Mexico to Tackle Medical Staffing Issues"
                           text="In a strategic move aimed at alleviating medical staffing challenges in California, Optumus has joined forces with Latinx Physicians of California to offer innovative solutions while driving significant cost savings. Through remote staff augmentation, medical practices across the state have reported remarkable reductions of approximately 40% in the expenses associated with fully [...]"
                        />
                        <BlogSingleItem 
                           image="assets/img/blog/blog1/athenahealth-marketplace.jpg" 
                           user_image="1" 
                           name="Optumus Insights" 
                           entryURL="/optumus-joins-athenahealth-marketplace" 
                           date="Dec 1, 2023"
                           title="Optumus, LLC Joins athenahealth’s Marketplace Program to Provide Dedicated Staff Augmentation Services to Medical Practices in the United States"
                           text="Optumus, LLC a provider of dedicated staff augmentation services today announced an agreement with athenahealth, Inc. through the company’s Marketplace program. “Optumus, LLC’s staff augmentation services help medical practices and hospitals solve ongoing staffing issues left behind by COVID-19 through providing dedicated, college educated, bilingual (English/Spanish) employees [...]"
                        />

                        <div className="basic-pagination" >
                           <ul>
                              <li>
                                 <Link to="#">
                                    <i > <CgArrowLongLeft /> </i>
                                    <i > <CgArrowLongLeft /> </i>
                                 </Link>
                              </li>
                              <li className="active">
                                 <Link to="#">
                                    <span>1</span>
                                    <span>1</span>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="#">
                                    <i > <CgArrowLongRight /> </i>
                                    <i > <CgArrowLongRight /> </i>
                                 </Link>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>

                  <BlogLeftSideBarNew/>
               </div>
            </div>
         </section>
      </>
   );
};

export default BlogsArea;