import React from 'react';
import SingleService from '../../../components/SingleService/SingleService';


const HomeServices = () => {
   return (
      <>
         <section className="services__area pt-115" id = "services">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 text-center mb-90 wow fadeInUp" data-wow-delay=".2s">
                        <span>Our Services</span>
                        <h2>Our services are designed to help you provide exceptional patient care.</h2>
                     </div>
                  </div>
               </div>
               <div className="row text-center">

                  <SingleService faicon="BsPeople" facolor="#00a3d4" title="Staff Augmentation" linkurl="/staff-augmentation" description="Finding the very best talent can be a time-consuming process. Our long-term staff supplementation services will allow you to enjoy a hands-off approach, reducing the administrative burdens you face while guaranteeing the very best employees. Thanks to our proprietary implementation process, Optumus has grown to become a hub of knowledge and a means for standardization, and through personalized and real-time analytics, you will always be able to enjoy a birds-eye overview." />
                  <SingleService faicon="BsClipboardData" facolor="#00a3d4" title="Consulting" linkurl="/consulting" description="In need of some advice? Looking for some guidance from experienced industry professionals? Whatever it might be, here at Optumus, we are able to provide world-leading consulting solutions to our clients. Our talented consultants have decades of experience working across the industry, meaning we can support and advise on every project, no matter its size." />
                  <SingleService faicon="BsGear" facolor="#00a3d4" title="Development" linkurl="/development" description="The power of the modern healthcare system lies in its ability to leverage all of its capabilities. At Optumus, our development and reporting services are designed to help you maximize the potential of your systems. Our one-stop-shop approach allows you to save operational and administrative time and resources, while our comprehensive reporting ensures you can make stronger and better-informed decisions." />

               </div>
            </div>
         </section>
      </>
   );
};

export default HomeServices;
