import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeTwoServices from './HomeTwoServices/HomeTwoServices';
import HomeTwoFaq from './HomeTwoFaq/HomeTwoFaq';
import HomeTwoFeatures from './HomeTwoFeatures/HomeTwoFeatures';
import Footer from '../../components/shared/Footer';
import HomeTwoHeader from './HomeTwoHeader/HomeTwoHeader';
import HomeTwoHeroSlider from './HomeTwoHeroSlider/HomeTwoHeroSlider';
import HomeTwoAbout from './HomeTwoAbout/HomeTwoAbout';
import HomeFeatures from '../Home/HomeFeatures/HomeFeatures';
import HomeTwoTestimonial from '../HomeTwo/HomeTwoTestimonial/HomeTwoTestimonial';

const HomeTwo = () => {
   return (
      <>
         <PageHelmet 
            pageTitle="Optumus | Healthcare Staffing & Consulting"
            description="We provide industry-leading staffing and technology solutions to hospitals, health systems, and physician groups across the country."
         />

         <HomeTwoHeader/>
         <HomeTwoHeroSlider/>
         <HomeTwoAbout/>
         <HomeTwoFaq/>
         <HomeTwoServices/>
         <HomeTwoTestimonial/>
         <CommonCtaArea/>
         <Footer/>
      </>
   );
};

export default HomeTwo;
