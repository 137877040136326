import React from 'react';

const PrivacyPolicyCapabilities = () => {
   return (
      <>
         <section className="capabilities__area p-relative black-bg-2 policy-padding fix">
            <div className="capabilities__thumb p-absolute" style={{ background:`url(assets/img/privacy/security.jpg)`
               , backgroundPosition: 'center', backgroundSize:'cover'}}></div>
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-6" >
                     <div className="capabilities__content wow fadeInUp" data-wow-delay=".4s">
                        <div className="section__title section__title-2 mb-60">
                           {/* <span className="white-color">Capabilities</span> */}
                           {/* <h2 className="white-color">Who We Are?</h2> */}
                        </div>
                        <div className="capabilities-paragraph capabilities-text">
                          <b>5. Security Measures</b>
                          <p>We employ industry-standard security measures to protect your information from unauthorized access and disclosure.</p>

                          <b className="subtitle">6. Your Choices</b>
                          <p>You can:
                          <br />
                            •	Opt-out of receiving communications related to your services
                          <br />
                            •	Request Optumus to correct or delete your personal information</p>

                          <b className="subtitle">7. Third-Party Links</b>
                          <p>Our services may contain links to third-party websites. We are not responsible for the privacy practices or content of these sites.</p>

                          <b className="subtitle">8. Updates to This Privacy Policy</b>
                          <p>We may update this policy periodically. Check the "Last Updated" date to see the latest changes.</p>

                          <b className="subtitle">9. Contact Us</b>
                          <p>If you have questions or concerns about this Privacy Policy, contact us at info@optumus.com</p>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default PrivacyPolicyCapabilities;