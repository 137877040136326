import React from 'react';
import { FaRegComment, FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const BlogLeftSideBar = () => {
    return (
        <>
            <div className="col-xl-4 col-lg-4">
                <div className="blog__sidebar">
                    <div className="sidebar__widget mb-75 ">
                        <div className="sidebar__widget-title mb-50">
                            <h4>Recent Entries</h4>
                        </div>
                        <div className="sidebar__widget-content">
                            <div className="rc-post">
                                <ul>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/insights-lpoc-partners-with-optumus"><img className="blog-image" src="assets/img/blog/blog2/latinx-physicians-of-california-icon.jpg"
                                                alt="AthenaHealth Marketplace" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6>
                                                <Link to="/insights-lpoc-partners-with-optumus">Latinx Physicians of California Partners with Optumus Mexico to Tackle Medical Staffing Issues</Link>
                                            </h6>
                                            <div className="rc-meta"><span>Apr 5, 2024</span> </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="sidebar__widget-content">
                            <div className="rc-post">
                                <ul>
                                    <li className="d-flex mb-20">
                                        <div className="rc-thumb mr-15">
                                            <Link to="/optumus-joins-athenahealth-marketplace"><img className="blog-image" src="assets/img/blog/blog1/athenahealth-marketplace-icon.jpg"
                                                alt="AthenaHealth Marketplace" /></Link>
                                        </div>
                                        <div className="rc-text">
                                            <h6>
                                                <Link to="/optumus-joins-athenahealth-marketplace">Optumus, LLC Joins athenahealth’s Marketplace Program to Provide Dedicated Staff Augmentation [...]</Link>
                                            </h6>
                                            <div className="rc-meta"><span>Dec 1, 2023</span> </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogLeftSideBar;