import React from 'react';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import StyleHeaderSeven from '../HeaderStyleSeven/StyleHeaderSeven/StyleHeaderSeven';
import StaffingDetailsArea from './StaffingDetailsArea/StaffingDetailsArea';
import StaffingDetailsHeader from './StaffingDetailsHeader/StaffingDetailsHeader';
import HomeTwoExpertArea from '../../pages/HomeTwo/HomeTwoExpertArea/HomeTwoExpertArea';
import StaffingInformation from './StaffingInformation/StaffingInformation';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';

const StaffingDetails = () => {
   return (
      <>
         <PageHelmet 
            pageTitle="Staff Augmentation Services | Optumus"
            description="We know the importance of effective recruiting, which is why our mission is to relieve the administrative burden so you can focus on running your business."
         />

         <StyleHeaderSeven />
         {/* <StaffingDetailsHeader /> */}
         <CommonPageHeader title="Staff Augmentation Services" />
         <StaffingInformation />
         <StaffingDetailsArea />
         <Footer />
      </>
   );
};

export default StaffingDetails;
