import React from 'react';
import { BiMap } from 'react-icons/bi';
import { FaEnvelope, FaFacebookF, FaPhoneAlt, FaTwitter, FaVimeoV } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const AboutInformation = () => {
  return (
    <>
      <section className="team__details pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 offset-xl-2">
              <div className="team__details-info pt-100 team-details-paragraph text-center">
                  <h4 >What We Believe </h4>
                  <p>Here at Optumus, we do not want to simply be seen as another vendor but instead as a partner to your success. That is why our mission has always been to ensure the services we provide are always focused on efficiency, quality, and compassion.</p>
                  <p>Over time, our services have grown organically as clients face new challenges and ask us how we can help them overcome these issues. Being a partner in your success is the motivation we need to keep growing and expanding our services while remaining true to our original ethos.</p>
              </div>
              <div className="services__img mb-45 w-img img-radius-about">
                  <img src="assets/img/capabilities/capabilities-img.jpg" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutInformation;