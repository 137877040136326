import React from 'react';
import Slider from "react-slick";

const HomeTwoTestimonial = () => {
   // slick setting
   const settings = {
      autoplay: true,
      autoplaySpeed: 15000,
      dots: true,
      fade: false,
      arrows: true,

   }
   const testimonialData = [
      {
         id:1,
         name:'Michael Harunkiewicz',
         description: 'Optumus was able to greatly reduce the burden on our practice staff and clinicians by providing staff augmentation to assist with data migration. What sets Optumus apart from the field is their commitment to not only provide resources, but ensure that the product produced by their resources is the best achievable quality. Optumus’ work surpassed expectations and we wouldn’t hesitate to use them again.',
         title:'EMR Manager - Emerson Hospital - Concord, MA',
      },
      {
         id:2,
         name:'Will Newman',
         description: 'The Optumus team supported our practices at a time when we couldn’t find consistency or stability with staffing.  Their rigorous training and retraining process ensures that quality is met each time.  Optumus management and staff are engaged, responsive and committed to the success of each customer.',
         title:'Administrator - Forefront Medical Management - Mobile, AL',
      },
      {
         id:3,
         name:'Paige Glass',
         description: 'We worked with Optumus to set up a call center for our multi-location, multi-doctor orthopedic practice.  From the planning through training and go-live, the process was smooth and the attention to detail and customer service was and remains outstanding.  We are pleased and looking at other services to outsource.',
         title:'Practice Administrator - Orthopaedic Associates - Houston, TX',
      },
      {
         id:4,
         name:'Shelly Pitts',
         description: 'Optumus has helped us better utilize our internal resources and has allowed us to continue providing services to our patients without having to worry about staffing. Their knowledge in billing, revenue cycle management, and referral management is invaluable to our organization.',
         title:'MSN, RN, CNOR - Director of Operations - CMF Surgeons - Medford, MA',
      }
   ]

   return (
      <>
         <section className="section-testimonials testimoinal__area gradient-bg mt-40">
            <div className="container pb-60">
               <div className="row">
                  <div className="col-xl-5 col-lg-6">
                     <div className="testimonial__content pt-70 pb-175">
                        <div className="section__title section__title-3 mb-30">
                           {/* <span className="">Testimonials</span> */}
                           <h2 className="">Featured Client Success Stories</h2>
                        </div>

                        <Slider className='testimonial__slider' {...settings}>

                           {
                              testimonialData.map((testimonial,index) => {
                                 return <div key={index} className="testimonial__item">
                                    <i><p className="">{ testimonial.description }</p></i>
                                    <div className="testimonial__content d-flex align-items-center">
                                       <div className="quote mr-20">
                                          <img src="assets/img/icon/testimonial/quote.png" alt="quote" />
                                       </div>
                                       <div className="testimonial__info">
                                          <h4 className="">{testimonial.name}</h4>
                                          <span className="">{testimonial.title}</span>
                                       </div>
                                    </div>

                                 </div>
                              })
                           }

                        </Slider>

                     </div>
                  </div>
                  <div className="col-xl-7 col-lg-6">
                     <div className="testimonial__thumb m-img text-end pt-40 " >
                        <img src="assets/img/testimonial/testimonial-1.png" alt="testimonbial" />
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoTestimonial;