import React from 'react';
import { Helmet } from "react-helmet";

const PageHelmet = ({ pageTitle, description, slug }) => {
    const env = 'build'
    const basehref = (env == 'build') ? 'https://optumus.com/' + window.location.pathname.split("/").pop()
    : window.location.href
    // const basehref = 'https://optumus.com/'

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ pageTitle }</title>
                <meta name="description" content={description} />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                {/* <base href="https://optumus.com" /> */}
                {/* <link href="https://optumus.com" rel="home" /> */}
                <link href={basehref} rel="canonical" />
                {/* <meta name="description" content={window.location.host} /> */}
                {/* <script src="//code.tidio.co/qzpe1gciwcxdmxe1ozsd45bf0oc1hkju.js" async></script> */}
            </Helmet>
        </>
    );
};

export default PageHelmet;
