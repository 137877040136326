import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import PageHelmet from '../../components/shared/PageHelmet';
import Footer from '../../components/shared/Footer';
import StyleHeaderSeven from '../HeaderStyleSeven/StyleHeaderSeven/StyleHeaderSeven';
import BlogsArea from './BlogsArea/BlogsArea';

const Blogs = () => {
   return (
      <>
         <PageHelmet 
            pageTitle="Insights | Optumus"
            description="Insights and press releases."
         />

         <StyleHeaderSeven/>

         <CommonPageHeader title="Insights" subtitle="" />
         <BlogsArea/>
         <Footer/>
      </>
   );
};

export default Blogs;