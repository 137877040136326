import React,{useState} from 'react';
import { Link, NavLink } from 'react-router-dom';
import useGlobalContext from '../../../hooks/useGlobalContext';
import Sidebar from '../../../components/Sidebar/Sidebar';

const StyleHeaderSeven = () => {

   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();

   return (
      <>
         <header>
            <div className="header__area p-relative header__transparent">
               <div id="header__sticky" className={stickyMenu ? 'sticky header__bottom header__bottom-2' : 'header__bottom header__bottom-2'}>
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                           <div className="logo">
                              <NavLink to="/">
                                 <img src="assets/img/logo/logo.png" alt="logo"/>
                              </NavLink>
                           </div>
                           <div className="logo-gradient">
                              <NavLink to="/">
                                 <img src="assets/img/logo/logo-gradient.png" alt="logo"/>
                              </NavLink>
                           </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6">
                           <div className="header__bottom-right d-flex justify-content-end align-items-center">
                            <div className="main-menu menu_wrapper_one">
                                 <nav id="mobile-menu">
                                    <ul>
                                       <li><NavLink to="/">Home</NavLink></li>
                                       <li className="menu-services">
                                          <NavLink to="">Services</NavLink>
                                          <ul className="submenu">
                                             <li><NavLink to="/staff-augmentation">Staff Augmentation</NavLink></li>
                                             <li><NavLink to="/consulting">Consulting</NavLink></li>
                                             <li><NavLink to="/development">Development</NavLink></li>
                                          </ul>
                                       </li>
                                       <li><NavLink to="/about">About Us </NavLink></li>
                                       <li><NavLink to="/insights">Insights</NavLink></li>
                                       <li><NavLink to="/contact">Contact Us</NavLink></li>
                                    </ul>
                                 </nav>
                              </div>
                              <div className="header__btn d-none d-sm-block d-xl-block ml-50">
                                 <Link to="/contact" className="z-btn z-btn-white z-btn-white-2">Get a Quote</Link>
                              </div>
                              <div onClick={handleShow} className="sidebar__menu d-lg-none">
                                 <div className="sidebar-toggle-btn" id="sidebar-toggle">
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>


         <Sidebar show={show} handleClose={handleClose} />
      </>
   );
};

export default StyleHeaderSeven;
