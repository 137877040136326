import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicyAchievement = () => {
   return (
      <>
         <section className="achievement__area pt-135 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="achievement__thumb m-img text-right about-img">
                      <img src="assets/img/privacy/privacy.jpg" alt="Security"/>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6">
                     <div className="achievement__content privacy">
                        <div className="section__title section__title-3 mb-20">
                           {/* <span>What We Do</span> */}
                           <h2>Privacy Policy</h2> 
                           <small>Last Updated: 12/21/2023</small>
                        </div>
                        
                        <b>1. Introduction</b>
                        <p>Welcome to Optumus! This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information. By using our services, you agree to the terms outlined in this policy.</p>

                        <b className="subtitle">2. Information We Collect</b>
                        <p>We may collect the following types of information:</p>
                        <p>•	Personal Information: Names, email addresses, cell phone numbers.</p>
                         
                        <b className="subtitle">3. How We Use Your Information</b>
                        <p>We use the collected information for the following purposes:</p>
                        <p>•	Communicate with you, regarding services you have contracted with Optumus</p>

                        <b className="subtitle">4. Information Sharing</b>
                        <p>We may share your information with:</p>
                        <p>•	Service providers and partners who assist in delivering our services</p>
                        <p>•	Legal authorities when required by law</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default PrivacyPolicyAchievement;