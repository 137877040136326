import React from 'react';
import { Link } from 'react-router-dom';
import {  FaUsers } from 'react-icons/fa';
import {  BsPeople, BsClipboardData, BsGear } from 'react-icons/bs';

const faComponent = (faName, facolor) => {
   const mapper = { FaUsers, BsPeople, BsClipboardData, BsGear }
   const ThisComponent = mapper[faName]
   return <ThisComponent color={facolor} />
}

const SingleService = ({icon, faicon, facolor, title, description, linkurl}) => {
   return (
      <>
         <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <div style={{alignItems: 'center',}} className="services__item">
               {
                  icon && <div className="services__icon mb-35">
                     <img src={`assets/img/icon/services/services-${icon}.png`} alt="services"/>
                  </div>
               }
               {
                  faicon && <div className="services__icon mb-35">
                     <p><i className="services-icon">{ faComponent(faicon, facolor) }</i></p>
                  </div>
               }
               <div className="services__content">
                  <h3><Link to={linkurl}>{title}</Link></h3>
                  <p>{ description || null }</p>
               </div>
            </div>
         </div>
      </>
   );
};

export default SingleService;
