import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FaEnvelope, FaFacebookF, FaPhoneAlt, FaTwitter, FaVimeoV, FaLinkedinIn, FaYoutube } from 'react-icons/fa';

const HomeThreeFooter = () => {
   return (
      <>
         <footer>
            <div className="footer__area grey-bg pt-100">
               <div className="footer__top pb-45 footer__limit-width">
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6 pr-0">
                           <div className="footer__widget wow fadeInUp" data-wow-delay=".2s">
                              <div className="footer__widget-title mb-30">
                                 <div className="logo">
                                    <NavLink to="/">
                                       <img src="assets/img/logo/logo-black.png" alt=""/>
                                    </NavLink>
                                 </div>
                              </div>
                           </div>
                           <div className="footer__widget-content">
                              <div className="footer__social theme-social mb-30">
                                 <ul>
                                    <li>
                                       <a href="https://www.facebook.com/myoptumus" target="_blank" rel="noreferrer">
                                          <i ><FaFacebookF /></i>
                                          <i ><FaFacebookF /></i>
                                       </a>
                                    </li>
                                    <li>
                                       <a href="https://twitter.com/myoptumus" target="_blank" rel="noreferrer">
                                          <i ><FaTwitter /> </i>
                                          <i ><FaTwitter /> </i>
                                       </a>
                                    </li>
                                    <li>
                                       <a href="https://www.linkedin.com/company/myoptumus/" target="_blank" rel="noreferrer">
                                          <i ><FaLinkedinIn /> </i>
                                          <i ><FaLinkedinIn /> </i>
                                       </a>
                                    </li>
                                    <li>
                                       <a href="https://www.youtube.com/@optumus1922/videos" target="_blank" rel="noreferrer">
                                          <i ><FaYoutube /> </i>
                                          <i ><FaYoutube /> </i>
                                       </a>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                           <div className="footer__widget footer__widget-3 mb-45 wow fadeInUp" data-wow-delay=".4s">
                              <div className="footer__widget-title">
                                 <h4>Company</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links">
                                    <ul>
                                       <li><NavLink to="/about">About Us</NavLink></li>
                                       <li><NavLink to="/insights">Insights</NavLink></li>
                                       <li><NavLink to="/contact">Contact Us</NavLink></li>
                                       <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".6s">
                              <div className="footer__widget-title">
                                 <h4>Services</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links">
                                    <ul>
                                       <li><NavLink to="/staff-augmentation">Staff Augmentation</NavLink></li>
                                       <li><NavLink to="/consulting">Consulting</NavLink></li>
                                       <li><NavLink to="/development">Development</NavLink></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                           <div className="footer__widget footer__widget-3 mb-45 wow fadeInUp" data-wow-delay=".8s">
                              <div className="footer__widget-title">
                                 <h4>Information</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__info mb-35">
                                    <ul>
                                       <li>
                                          <div className="icon theme-color ">
                                             <i> <FaEnvelope/> </i>
                                          </div>
                                          <div className="text theme-color ">
                                             <span><a href="mailto:info@optumus.com">info@optumus.com</a></span>
                                          </div>
                                       </li>
                                       <li>
                                          <div className="icon theme-color">
                                             <i > <FaPhoneAlt/> </i>
                                          </div>
                                          <div className="text theme-color">
                                             <span><a href="tel:(+617)-848-4488">(617) 848-4488</a></span>
                                          </div>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="footer__copyright-3">
                  <div className="container">
                     <div className="footer__copyright-inner">
                        <div className="row align-items-center">
                           <div className="col-xl-12">
                              <div className="footer__copyright-text text-center">
                                 <p>Copyright © { new Date().getFullYear() } All Rights Reserved</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

export default HomeThreeFooter;
