import React from 'react';
import { Link } from 'react-router-dom';

const HomeTwoFirstSlide = ({ slider_class,content_class, attr_1, attr_2, attr_3 = "Find out more about our services today.",btn_text}) => {
   return (
      <>
         <div className="single-slider single-slider-2 slider__height slider__height-2 d-flex align-items-center" style={{
            background: `url(assets/img/slider/02/slider-1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }}>

            <div className="container">
               <div className="row">
                  <div className={`${slider_class}`}>
                     <div className={`slider__content slider__content-2 ${content_class && content_class}`}>
                         {attr_1 && attr_1}
                         {attr_2}
                        <p >{attr_3}</p>
                        <div className="slider__btn">
                           <a className="z-btn z-btn-transparent" href="#aboutus">Who are we?</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default HomeTwoFirstSlide;
