import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import StyleHeaderSeven from '../HeaderStyleSeven/StyleHeaderSeven/StyleHeaderSeven';
import ContactArea from '../../pages/Contact/ContactArea/ContactArea';
import NotFoundInfoArea from './NotFoundInfoArea/NotFoundInfoArea';

const NotFound = () => {
   return (
      <>
         <PageHelmet 
            pageTitle="404 - Page Not Found | Optumus" 
            description="404 - Page Not Found"
         />

         <StyleHeaderSeven/>
         <CommonPageHeader title="404" subtitle="Page Not Found" />
         <NotFoundInfoArea/>
         <Footer/>
      </>
   );
};

export default NotFound;
