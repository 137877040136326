import { CgArrowLongRight } from 'react-icons/cg';
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const HomeTwoAbout = () => {
   return (
      <>
         <section className="about__area pb-200 pt-150"  id = "aboutus">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 pr-0 col-lg-6">
                     <div className="about__thumb m-img">
                        <img src="assets/img/about/about-1.jpg" alt=""/>
                           <div className="about__shape">
                              <img src="assets/img/about/red-shape.png" alt=""/>
                           </div>
                     </div>
                  </div>
                  <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
                     <div className="about__content">
                        <div className="section__title mb-25">
                           <span>Who we are</span>
                           <h2>We unlock your potential</h2>
                        </div>
                        <p>Every healthcare organization is dedicated to patient care, and our mission here at Optumus is to help you ease your business's administrative and technological burdens. With our support, you can get back to doing what matters most: giving your patients the best care possible.</p>
                        <p>We are committed to providing our customers with a comprehensive array of services, and behind each one, you can be guaranteed of:</p>
                        <div className="about__list">
                           <ul>
                              <li ><span><i > <FaCheck/> </i>Exceptional quality</span></li>
                              <li ><span><i > <FaCheck/> </i>Unrivalled efficiency</span></li>
                              <li ><span><i > <FaCheck/> </i>Warmth and compassion</span></li>
                           </ul>
                        </div>
                        <Link to="/about" className="z-btn " >More About Us<i > <CgArrowLongRight /> </i></Link>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoAbout;
