import React from 'react';
import DevelopmentCard from '../../DevelopmentDetails/DevelopmentCard/DevelopmentCard';

const DevelopmentExpertArea = () => {
   return (
      <>
        <section 
          className="expart__area pt-150 wow fadeInUp" 
          data-wow-delay=".4s"
          style={{ backgroundImage: 'url(../img/bg/wave-bg.png)' }}
        >
            <div className="container pb-150">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="expart__wrapper">
                        <div className="expart__nav">
                           <ul className="nav nav-pills justify-content-end" id="expart-tab" role="tablist">
                              <li className="nav-item text-center">
                                 <a className="nav-link active" id="content-tab" data-bs-toggle="pill" href="#content" role="tab" aria-controls="content" aria-selected="true">Custom Clinical Content </a>
                              </li>
                              <li className="nav-item text-center">
                                 <a className="nav-link" id="analytics-tab" data-bs-toggle="pill" href="#analytics" role="tab" aria-controls="analytics" aria-selected="false">Custom Analytics</a>
                              </li>
                              <li className="nav-item text-center">
                                 <a className="nav-link" id="solutions-tab" data-bs-toggle="pill" href="#solutions" role="tab" aria-controls="solutions" aria-selected="false">Custom Software Solutions</a>
                              </li>
                           </ul>
                        </div>
                        <div className="expart__tab">
                           <div className="tab-content" id="expart-Content">
                              <div className="tab-pane fade show active" id="content" role="tabpanel" aria-labelledby="content-tab">
                                <DevelopmentCard image="1" title="Custom Clinical Content ">
                                  <p>One of the most common issues healthcare organizations face is that their clinical documentation content is slow, inefficient, and often irrelevant. This could be because the content was not very good to begin with, but it can also be due to it devolving over time.</p>
                                  <p>One of the most common complaints our team receives is that our clients find that they are spending too much time instead of helping patients. That is why one of our founding services was our clinical content customization, reducing administration and pulling our clients away from their screens, giving them more time to care for their patients.</p>
                                </DevelopmentCard>
                              </div>
                              <div className="tab-pane fade" id="analytics" role="tabpanel" aria-labelledby="analytics-tab">
                                <DevelopmentCard image="2" title="Custom Analytics ">
                                  <p>Comprehensive data allows you to understand every aspect of your business and elevate your brand, but poor presentation can be misleading and frustrating. That is why we utilize a variety of industry-standard tools to help you to not only unlock the most insightful data possible but also interpret it.</p>
                                  <p>From clinical data to track patient care and financial data to provide insights on staff performance to process data to highlight inefficiencies, we can support you with it all. Our experienced analytic consultants will work closely with you to help you identify what information you need and the best way to present it.</p>
                                </DevelopmentCard>
                              </div>
                              <div className="tab-pane fade" id="solutions" role="tabpanel" aria-labelledby="solutions-tab">
                                <DevelopmentCard image="3" title="Custom Software Solutions ">
                                  <p>If you have often found that you feel something is missing from your current software solutions or have to undertake too many manual or repetitive tasks, then Optumus is here to help you. Our experienced team has a custom software development team that specializes in small and niche applications to address these issues.</p>
                                  <p>These small solutions can either bolt onto existing applications or as standalone software and are designed to make your workload easier and simpler.</p>
                                </DevelopmentCard>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
        </section>
      </>
   );
};

export default DevelopmentExpertArea;