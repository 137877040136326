import React from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import SingleService from '../../../components/SingleService/SingleService';
import StaffingSingleService from '../../../pages/Staffing/StaffingSingleService/StaffingSingleService';
import { SiKnowledgebase } from 'react-icons/si';
import { BsTools, BsHeart, BsBag, BsHeadset, BsClipboardCheck, BsArrowClockwise, BsListCheck } from 'react-icons/bs';

const StaffingDetailsArea = () => {
    return (
        <>
          <section className="services__area-3 pb-160">
            <div className="container">
               <div className="row">
                  <div className="col-xl-10 offset-xl-1 text-center">
                     <div className="team__details-info pt-70 ">
                        <h4 >Staffing Services</h4>
                        <p>
                           At Optumus, we are continuously expanding our services to meet the changing demands of the modern world.
                           <br />
                           The current areas we can support our clients with include:
                        </p>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-12">
                     <div className="services__nav wow fadeInUp" data-wow-delay=".4s">
                        <ul className="nav nav-pills " id="services-tab" role="tablist">
                           <li className="nav-item mb-45">
                              <a className="nav-link active" id="virtual-tab" data-bs-toggle="pill" href="#virtual" role="tab" aria-controls="virtual" aria-selected="true">
                                 <i> <BsHeadset/> </i>Virtual Reception 
                              </a>
                           </li>
                           <li className="nav-item mb-30">
                              <a className="nav-link" id="prior-tab" data-bs-toggle="pill" href="#prior" role="tab" aria-controls="prior" aria-selected="true">
                                 <i ><BsClipboardCheck/></i>Prior Authorization
                              </a>
                           </li>
                           <li className="nav-item mb-30">
                              <a className="nav-link" id="cycle-tab" data-bs-toggle="pill" href="#cycle" role="tab" aria-controls="cycle" aria-selected="true">
                                 <i ><BsArrowClockwise/></i>Revenue Cycle Management
                              </a>
                           </li>
                           <li className="nav-item mb-30">
                              <a className="nav-link" id="indexing-tab" data-bs-toggle="pill" href="#indexing" role="tab" aria-controls="indexing" aria-selected="true">
                                 <i ><BsListCheck/></i>Indexing
                              </a>
                           </li>
                           <li className="nav-item mb-30">
                              <a className="nav-link" id="special-tab" data-bs-toggle="pill" href="#special" role="tab" aria-controls="special" aria-selected="true">
                                 <i ><BsTools/></i> Special Projects
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-xl-12">
                     <div className="services__tab grey-bg-18">
                        <div className="tab-content" id="services-content">
                           <div className="tab-pane fade show active" id="virtual" role="tabpanel" aria-labelledby="virtual-tab">
                              <StaffingSingleService 
                                 title="Virtual Reception"
                                 content={['Our virtual reception services align with the roles of any medical organization receptionist. This means that they can help with patient scheduling, answering phone calls, and supporting with medication refill requests.']}
                                 img="assets/img/staffing/services/visual-reception.jpg"
                              />
                           </div>
                           <div className="tab-pane fade" id="prior" role="tabpanel" aria-labelledby="prior-tab">
                              <StaffingSingleService 
                                 title="Prior Authorization" 
                                 content={[
                                    'Operating across both your front and back office functions, our prior authorization services focus on coordinating with insurance requests. From taking initial requests through to approval or denial, our agents will undertake all of the administrative tasks, removing one of the biggest burdens your operations face.',
                                    'We use a proprietary process to track all authorization management. This means you can access a custom-built application that will allow you to quickly see all active authorizations, including dates for initial and follow-up contact details and notes.'
                                 ]}
                                 img="assets/img/staffing/services/prior-authorization.jpg"
                              />
                           </div>
                           <div className="tab-pane fade" id="cycle" role="tabpanel" aria-labelledby="cycle-tab">
                              <StaffingSingleService 
                                 title="Revenue Cycle Management" 
                                 content={['Our Revenue Cycle Management (RCM) services are staffed by specially trained agents that have risen through the ranks of our company. This ensures that they are highly experienced and can leverage their knowledge to support you in processing denials, chasing down A/R, and transcribing any billing data during system migrations.']}
                                 img="assets/img/staffing/services/revenue-cycle.jpg"
                              />
                           </div>
                           <div className="tab-pane fade" id="indexing" role="tabpanel" aria-labelledby="indexing-tab">
                              <StaffingSingleService 
                                 title="Indexing" 
                                 content={['We are able to support you with indexing your documents into your EMR system, leveraging the tools you currently use for the process. This ensures that your external documents are promptly and securely loaded onto your system, and your providers can access them as required.']}
                                 img="assets/img/staffing/services/indexing.jpg"
                              />
                           </div>
                           <div className="tab-pane fade" id="special" role="tabpanel" aria-labelledby="special-tab">
                              <StaffingSingleService 
                                 title="Special Projects" 
                                 content={['We can also help you staff any special projects you have, such as supporting a system migration. This service is designed to help save your team time and money, and once the project is complete, our agents will move to new projects, ensuring that they are not laid off or made redundant.']}
                                 img="assets/img/staffing/services/projects.jpg"
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
        </>
    );
};

export default StaffingDetailsArea;
